import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {APP_INITIALIZER, ModuleWithProviders, NgModule} from '@angular/core';

import {FeatureToggleDirective} from './directives/feature-toggle.directive';
import {FeatureToggleGuard} from './guards/feature-toggle.guard';
import {FeatureToggleInitializerService} from './services/feature-toggle-initializer.service';
import {FeatureToggleService} from './services/feature-toggle.service';

export function featureToggleInitiliazer(service: FeatureToggleInitializerService) {
  return () => service.initFeatureToggle();
}

@NgModule({
  declarations: [FeatureToggleDirective],
  imports: [
    CommonModule,
    HttpClientModule,
  ],
  providers: [
    FeatureToggleGuard,
  ],
  exports: [FeatureToggleDirective],
})
export class FeatureToggleModule {

  constructor() {
  }
  static forRoot(): ModuleWithProviders<FeatureToggleModule> {
    return {
      ngModule: FeatureToggleModule,
      providers: [
        FeatureToggleService,
        FeatureToggleInitializerService,
        {
          provide: APP_INITIALIZER,
          useFactory: featureToggleInitiliazer,
          deps: [FeatureToggleInitializerService],
          multi: true,
        },
      ],
    };
  }
}
