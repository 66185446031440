import {Injectable} from '@angular/core';

import {FeatureToggleService} from './feature-toggle.service';

@Injectable()
export class FeatureToggleInitializerService {

  constructor(
    private readonly featureToggleService: FeatureToggleService,
  ) { }

  initFeatureToggle(): void {
    this.featureToggleService.init();
  }
}
