import {CommonModule} from '@angular/common';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {NgModule, TransferState} from '@angular/core';


import {FeatureToggleBrowserLoaderService} from './feature-toggle-browser-loader.service';

import {FeatureToggleLoaderService} from '../../models/feature-toggle';

export const featureToggleBrowserLoaderServiceFactory = (
  http: HttpClient,
  transferState: TransferState,
): FeatureToggleBrowserLoaderService => new FeatureToggleBrowserLoaderService(http, transferState);

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
  ],
  providers: [
    {
      provide: FeatureToggleLoaderService,
      useFactory: featureToggleBrowserLoaderServiceFactory,
      deps: [HttpClient, TransferState],
    },
  ],
})

export class FeatureToggleBrowserLoaderModule { }
