import {
  ChangeDetectorRef,
  Directive,
  EmbeddedViewRef,
  Input,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import {takeUntil} from 'rxjs/operators';

import {DestroySubscription} from '@helpers/destroy-subscription';

import {FeatureToggleService} from '../services/feature-toggle.service';

@Directive({
  selector: '[appFeatureToggle]',
})
export class FeatureToggleDirective extends DestroySubscription {

  private viewRef: EmbeddedViewRef<any> | null = null;
  private readonly templateRef: TemplateRef<any>;

  @Input() set appFeatureToggle(items: string[] | string | null) {
    this.init(items);
  }

  constructor(
    private readonly ref: TemplateRef<any>,
    private readonly viewContainerRef: ViewContainerRef,
    private readonly featureToggleService: FeatureToggleService,
    private readonly cdr: ChangeDetectorRef,
  ) {
    super();
    this.templateRef = this.ref;
  }

  private init(items: string[] | string | null): void {
    if (!items) {
      this.createView();
      return;
    }
    this.featureToggleService.featuresList$
      .pipe(
        takeUntil(this.destroyStream$),
      ).subscribe(features => {
        const enabledFeature = features ? this.featureToggleService.isFeatureEnabled(items) : false;
        if (!enabledFeature) {
          this.clearView();
          return;
        }
        this.createView();
      });
  }

  private createView(): void {
    if (!this.viewRef) {
      this.viewRef = this.viewContainerRef.createEmbeddedView(this.templateRef);
      this.cdr.detectChanges();
    }
  }

  private clearView(): void {
    this.viewContainerRef.clear();
    this.viewRef = null;
  }

}
